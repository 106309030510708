.win_hub电脑css_浏览器边框 {
    text-align: center;
}


.win_hub电脑css_程序边框 {
    width: 40%;
    border: gray 1px solid;
    display: inline-block;
}


