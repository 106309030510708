.作品进度记录-放第几集的里面的那个小方框 {
    overflow: scroll;
    width: 90%;
    height: 90%;
    margin-left: 5%;
    margin-top: 10px;
}

.作品进度记录-放第几集的里面的那个小方框::-webkit-scrollbar {
    display: none;
}


.第几个小时 {
    width: 90%;
    height: 90%;
    margin-left: 5%;
    margin-top: 10px;
}


.第几个分钟 {
    width: 90%;
    height: 90%;
    margin-left: 5%;
    margin-top: 10px;
    overflow: scroll;
}

.第几个分钟::-webkit-scrollbar {
    display: none;
}

.作品进度记录-作品列表 {
    /*overflow: scroll;*/
    /*width: 90%;*/
    /*height: 90%;*/
    /*margin-left: 5%;*/
    /*margin-top: 10px;*/

    position: absolute;
    /*border: red 1px solid;*/
    height: 60%;
    width: 100%;
    overflow: scroll;
}

.作品进度记录-作品列表::-webkit-scrollbar {
    display: none;
}