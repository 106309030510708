.rightClick {
    position: fixed;
    left: 100px;
    top: 100px;
    /*border: #cbeeff solid 1px;*/
    border: #c8c8c8 solid 1px;
    border-radius: 5px;
    background-color: RGB(245, 245, 245);
}

.Buttons {
    margin: 10px;
}

.Button {
    margin-bottom: 4px;
}